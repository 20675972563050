<template>
  <div class="app-contianer" style="padding-bottom: 0">
    <img style="width: 100%" :src="imgUrl" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'puertion',
  data() {
    return {
      imgUrl: ''
    }
  },
  computed: {
    ...mapState('user', [
      'agentConfig'
    ]),
  },
  created() {
    this.imgUrl = this.agentConfig.ORDER_RULE_IMAGE || require('../../assets/screenshot/purtion.jpg')
  }
}</script>
